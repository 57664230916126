<template>
    <div class="row justify-content-center h-100">
      <b-modal ref="my-modal" hide-footer hide-header centered title="BootstrapVue">
        <!-- <p class="my-4 text-success">Proof of payment updated successfully</p> -->
        <p class="my-4 text-success">ENROLLMENT SUBMITTED SUCCESSFULLY</p>
      </b-modal>
        <!-- loader -->
        <div id="loader" style="display: none">
          <!--      <img src="assets/img/loading-icon.png" alt="icon" class="loading-icon">-->
        </div>
        <!-- * loader -->
    
        <!-- App Header -->
        <div class="appHeader no-border transparent position-absolute">
          <div class="left">
            <a href="#" class="headerButton goBack">
              <!--          <ion-icon name="chevron-back-outline"></ion-icon>-->
            </a>
          </div>
          <div class="pageTitle"></div>
          <div class="right">
          </div>
        </div>
        <!-- * App Header -->
    
        <!-- App Capsule -->
        <div id="appCapsule" class="col-sm-6 col-lg-4 col-xl-4">
    
          <div class="section mt-2 text-center mb-4">
            <img src="../assets/img/Securus-main-logo-new.jpeg"/>
          </div>
          <div class="section mb-5 p-2">
    
            <form action="index.html" @submit.prevent="updateEmployee" ref="form">
              <div class="card">
                <div class="loader" v-if="isUploadingImage">
                  <b-spinner>please wait ...</b-spinner>
                  please wait ...
                </div>            
                <div class="card-body pb-1"> 
                  <template v-for="(data, i) of memberData">
                    <div class="form-group basic" :key="data.id" v-if="data.id === 1 
                    || data.id === 3">
                    <div class="input-wrapper">
                        <label class="label" for="name">{{ formatLabel[i] }}</label>                                      
                        <input v-model="form[data.id]" type="text" class="form-control" id="name" :placeholder="formatLabel[i]" readonly>                    
                    </div>
                  </div>
                  <div class="form-group basic" :key="data.id" v-if="data.id === 53">
                    <div class="input-wrapper">
                        <label class="label" for="name">Payment Date</label>                                      
                        <!-- <b-datepicker v-model="form[data.id]" :show-decade-nav="true"></b-datepicker>   -->
                        <date-picker
                        v-model="form[data.id]"
                        :config="config"
                        required
                        :placeholder="formatLabel[i]"
                        ></date-picker>                 
                    </div>
                  </div>
                  <div class="form-group basic" :key="data.id" v-if="data.id === 54">
                    <div class="input-wrapper">
                        <label class="label" for="name">Mode of Payment </label>                                      
                        <!-- <input v-model="form.modeOfPayment" type="text" class="form-control" id="name" placeholder="Mode of Payment"> -->
                        <select class="form-control" v-model="form[data.id]">
                          <option value="null" class="text-muted">--Select Mode of Payment--</option>
                          <option value="Cash" class="">Cash</option>
                          <option value="NEFT/ IMPS">NEFT/ IMPS</option>      
                          <option value="UPI">UPI</option>                  
                        </select>                   
                    </div>
                  </div>   
                  </template> 
                  <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="label" for="name">Enter UTR Number / Reference Number</label>                                      
                        <input v-model="form[55]"  type="text" class="form-control" id="name" placeholder="Enter UTR Number / Reference Number" @keyup="changeUTR">                    
                    </div>
                  </div>
                  <h3>or</h3>                                                                                 
                  <div class="form-group basic mb-5">
                    <div class="input-wrapper">
                        <label class="label button-label btn btn-primary" for="file">Attach Proof of Payment</label>                                      
                        <input type="file" hidden class="form-control" id="file" placeholder="Proof of Payment" @change="onFileChange">        
                      <!-- name of file chosen -->
                      <span id="file-chosen" v-if="fileName"><i class="fa fa-check"></i>{{ fileName }}</span>           
                    </div>
                  </div>       
                  <div class="transparent mb-1 mt-2">
                    <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="!buttonEnabled ? true : false"><b-spinner v-if="isWaitingResponse"></b-spinner><span v-else>Submit</span></button>
                  </div>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-if="v$.form[55].$error">
                      <div class="error-msg" style="font-size: 12px">Enter UTR/Reference Nnumber or attach proof of payment</div>
                  </div>
                </div>
              </div>
            </form>
          </div>
    
        </div>
        <!-- * App Capsule -->
      </div>
      
    </template>
    
    <script>
    import useVuelidate from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import datePicker from "vue-bootstrap-datetimepicker";
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    export default {
      name: 'UpdatePaymentDetails',
      data() {
        return {
          isWaitingResponse: false,
          employeeId: null,
          isUploadingImage: false,
          fileName: null,
          buttonEnabled: false,
          memberData: null,
          rowIndex: null,
          proofError: false,
          prospectId: null,
          config: {
              format: 'DD-MM-YYYY',
              minDate: this.$moment.subtract(150, 'years'),
              maxDate: this.$moment,
              date: this.$moment,
          },
          form: {
            55: null
          }
        }
      },
      components: {
        datePicker
      },
      created() {
        this.employeeId = this.$route.query.e?? ''  
        this.prospectId = this.$route.query.prospect_id
      },
      computed: {
        formatLabel() {
          let label = []
          this.memberData.map(item => {
            switch(item.name) {
              case 'First Name':
                label.push('Name of Employee')
                break;
              case "Employee ID":
                label.push('HR Number')
                break;
              case "Date of Birth":
                label.push('Date Of Birth of Ex-employee')
                break;
              case "Age":
                label.push('Age of Ex-employee')
                break;
              case "Gender":
                label.push('Gender of Ex-employee')
                break;
              case "Total Premium":
                label.push('Amount')
                break;
              default: 
                label.push(item.name)
            }   
          })
          return label;
        }
      },
      mounted() {
        let formD = new FormData()
        
        formD.append('employee_prospect_id', this.prospectId)
        formD.append('employee_id', this.employeeId)
        let form = {
          'employee_prospect_id': this.prospectId,
          'employee_id': this.employeeId
        }
        this.$nextTick(() => {
          this.isWaitingResponse = true
          this.isUploadingImage =  true
          this.$axiosEnrollment.post('get-employee-data', form).then(({data}) => {
            this.isWaitingResponse = false
            if(data.status === 1) {
              this.isUploadingImage = false
              this.memberData = data.data.member_data
              this.rowIndex = data.data.row_index
              }
            //fill data
            this.memberData.map((item) => {
              this.form[item.id] = item.value
              if(item.id === 55 && item.value) {
                this.buttonEnabled = true
              }
            })
    
    
          }).catch(() => {
            this.isUploadingImage = false
            this.isWaitingResponse = false
          })
          
        }) 
      },
      validations () {
        return {
          form: {
            55: {
              required
            }
          }
        }
      },
      methods: {
        changeUTR(e) {
          if(e.target.value) {
            this.buttonEnabled = true
          }
        },
        onFileChange(e) {
          this.isUploadingImage = true
          let file = e.target.files || e.dataTransfer.files
          if (!file.length) return
          let formD = new FormData();
          formD.append('file', file[0])
          this.$axiosFile.post('file-upload', formD).then(({data}) => {
            if(data.status === 1) {
               this.buttonEnabled = true
              this.isUploadingImage = false
              this.fileName = data.data.file_data.file_name
              this.form[55] = data.data.storage_url
            }
          }).catch(() => {
            this.isUploadingImage = false
          })
        },
        updateEmployee() {
          this.v$.$validate()
          if(this.v$.$error) {
            this.proofError = true
            return;
          }
          let memberDetails = []
          Object.entries(this.form).map(([key, value]) => {
            if(key == 53 || key == 55 || key == 54) memberDetails.push({id: key, value: value})
          })
          let form = {
            'employee_prospect_id': this.prospectId,
            'row_index': this.rowIndex,
            'member_details': memberDetails
          }
          //this.v$.$validate()
          //if(this.v$.$error) return
          this.isWaitingResponse = true
    
          this.$axiosEnrollment.post('update-employee', form).then(({data}) => {
            let _this = this;
            this.isWaitingResponse = false
            if(data.status === 1) {
              localStorage.setItem('LoggedUser', false)
              this.$refs['my-modal'].show()
              setTimeout(() => {
                _this.$router.push({
                  path: '/'
                })
              }, 2000)
            }
          }).catch(() => {
            this.isWaitingResponse = false
          })
        }
      },
      setup: () => {
        return { v$: useVuelidate() }
      }
    
    }
    </script>
    
    <style scoped>
    .button-label {
      color: white;
      cursor: pointer;
      width: 50%;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .loader {
      background:rgba(255, 255, 255, 0.6);
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    >>>#file-chosen {
      float: left;
      margin-left: 10px;
    }
    
    </style>
    